/*
 * @Author: elf9237 565071984@qq.com
 * @Date: 2024-08-02 23:41:08
 * @LastEditors: elf9237 565071984@qq.com
 * @LastEditTime: 2024-09-07 12:39:37
 * @FilePath: /ryapp/Users/xiaodong/Desktop/other/投票系统/源码/vote-admin/src/setting.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 请求接口地址 如果没有配置自动获取当前网址路径
//const VUE_APP_API_URL = process.env.VUE_APP_API_URL || `${location.origin}/api`;
const VUE_APP_API_URL = 'https://vote.shuzijianshe.com/api';
const Setting = {
  // 路由前缀
  routePre: '',
  // 接口请求地址
  apiBaseURL: VUE_APP_API_URL,
  // 路由模式，可选值为 history 或 hash
  routerMode: 'hash',
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
};

export default Setting;
